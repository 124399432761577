import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {debounce} from '@mui/material/utils';
import axios from "axios";

interface Address {
  "address": string,
  "coordinates": {
    "lat": number,
    "long": number
  },
  "kind": string
}

export function AddressSearchInput({onSelect}: { onSelect: (value: Address | null) => void }) {
  const [value, setValue] = React.useState<Address | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly Address[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (results?: readonly Address[]) => void,
        ) => {
          const {data} = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/locality?search=${request.input}`,
            {
              headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
            }
          );
          callback(data)
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results?: readonly Address[]) => {
      let newOptions: readonly Address[] = [];
      console.log(results);
      if (value) {
        newOptions = [value];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      setOptions(newOptions);
    });
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.address
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Не найдено"
      onChange={(event: any, newValue: Address | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onSelect(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Введите адрес" fullWidth/>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container sx={{alignItems: 'center'}}>
              <Grid item sx={{display: 'flex', width: 44}}>
                <LocationOnIcon sx={{color: 'text.secondary'}}/>
              </Grid>
              <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                  {option.address}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
